import { sendRequest } from './index';
import { API_PAYMENT_URL } from '@core/constants/API';

export const CourseAddToCart = (payload) =>
	sendRequest({
		url: `${API_PAYMENT_URL}carts/courses`,
		method: 'POST',
		body: payload
	});
export const LectureAddToCart = (payload) =>
	sendRequest({
		url: `${API_PAYMENT_URL}carts/lectures`,
		method: 'POST',
		body: payload
	});
export const getOrderListService = () =>
	sendRequest({
		url: `${API_PAYMENT_URL}orders`,
		method: 'GET'
	});

export const getOrderLinkSerivce = (id) =>
	sendRequest({
		url: `${API_PAYMENT_URL}orders/${id}/receipts`,
		method: 'GET'
	});

export const CartItemsService = () =>
	sendRequest({
		url: `${API_PAYMENT_URL}carts/items`,
		method: 'GET'
	});
export const CartMetaDataService = () =>
	sendRequest({
		url: `${API_PAYMENT_URL}carts/metadata`,
		method: 'GET',
		doNotRefresh: true
	});
export const CartDeleteItemService = (id) =>
	sendRequest({
		url: `${API_PAYMENT_URL}carts/items/${id}`,
		method: 'DELETE'
	});
export const AddDiscountService = (payload) =>
	sendRequest({
		url: `${API_PAYMENT_URL}discount`,
		method: 'POST',
		body: payload
	});
export const DeleteDiscountService = () =>
	sendRequest({
		url: `${API_PAYMENT_URL}discount`,
		method: 'DELETE'
	});

export const CheckoutService = (payload) =>
	sendRequest({
		url: `${API_PAYMENT_URL}checkout`,
		method: 'POST',
		body: payload
	});

export const PurchasedItemsService = (payload) =>
	sendRequest({
		url: `${API_PAYMENT_URL}orders/${payload}/purchased-items`,
		method: 'GET'
	});

export const getSubscriptionPlans = (userIp) =>
	sendRequest({
		url: `${API_PAYMENT_URL}subscription-plans`,
		method: 'GET',
		userIp: userIp
	});
export const GetSubscription = () =>
	sendRequest({
		url: `${API_PAYMENT_URL}subscriptions`,
		method: 'GET',
	});
export const SubscriptionService = (payload) =>
	sendRequest({
		url: `${API_PAYMENT_URL}subscriptions`,
		method: 'POST',
		body: payload
	});
export const SubscriptionDeactive = (payload) =>
	sendRequest({
		url: `${API_PAYMENT_URL}subscriptions/deactivate`,
		method: 'POST',
		body: payload
	});
export const SubscriptionReactivate = () =>
	sendRequest({
		url: `${API_PAYMENT_URL}subscriptions/reactivate`,
		method: 'POST',
	});
export const GetCustomerPortal = () =>
	sendRequest({
		url: `${API_PAYMENT_URL}customer-portals`,
		method: 'GET',
	});
export const UpgradeSubscription = () =>
sendRequest({
	url: `${API_PAYMENT_URL}subscriptions/upgrades`,
	method: 'POST',
});
export const GetUpgradeSubscriptionDetails = () =>
sendRequest({
	url: `${API_PAYMENT_URL}subscriptions/upgrades`,
	method: 'GET',
});






