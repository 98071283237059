export default {
	main: {
		primary: {
			main: '#BB3A90',
			light: '#fff0f7',
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: '#4ECDC4',
			light: '#EEFAFA'
		},
		gray: {
			10: '#f7f6f3',
			15: '#ecebe8',
			20: '#cccbc9',
			25: '#bfbfbf',
			30: '#a6a5a3',
			40: '#666664',
			50: '#403f3f',
			60: '#262626',
			70: '#0d0d0d'
		},
		alert: {
			main: '#d20101',
			light: '#ffe5e5'
		},
		warning: {
			main: '#FFF5E5',
			light: '#AD6C00'
		},
		success: {
			main: '#578a1d',
			ilght: '#e6f0da'
		}
	},
	dark:{
		mode: 'dark',
		background: {
			default: "#222222 !important"
		  },
		  
		primary: {
			main: '#bb3a90',
			light: '#fff0f7',
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: '#4ECDC4',
			light: '#EEFAFA'
		},
		gray: {
			10: '#f7f6f3',
			15: '#ecebe8',
			20: '#cccbc9',
			25: '#bfbfbf',
			30: '#a6a5a3',
			40: '#666664',
			50: '#403f3f',
			60: '#262626',
			70: '#0d0d0d'
		},
		alert: {
			main: '#d20101',
			light: '#ffe5e5'
		},
		warning: {
			main: '#FFF5E5',
			light: '#AD6C00'
		},
		success: {
			main: '#578a1d',
			ilght: '#e6f0da'
		}
	}
};
