import NotificationIcon from '@core/assets/icons/notification.svg';

import { useQuery } from 'react-query';
import { useMemo } from 'react';

import Popover from '@core/components/popover/Popover';
import usePopover from 'hooks/use-popover';

import { GetNotificationService } from 'services/notification';
import EmptyList from '../notificationcenter/EmptyList';
import NotificationList from '../notificationcenter/NotificationList';
import IconButton from '@core/components/iconbutton/IconButton';

import classes from './Notification.module.scss';
import { getAccessToken } from 'utils/authentication';

const Notification = () => {
	const { data, refetch } = useQuery('user-notification', GetNotificationService, {
		refetchInterval: 60000
	});
	const { show, destroyPopover, showPopover } = usePopover();
	
	const seenNotificationCount = useMemo(() => {
		return data?.data.results.filter((item) => !item.seen_at).length;
	}, [data?.data.results]);

	return (
		<div className={classes['notificaton-container']}>
			<IconButton badge={seenNotificationCount} onClick={showPopover} data-testid="notifBtn">
				<NotificationIcon role="img" />{' '}
			</IconButton>
			<Popover
				show={show}
				closeHandler={destroyPopover}
				title="Notifications"
				type="mobile-fullscreen">
				{data && data?.data.count !== 0 ? (
					<NotificationList
						data={data.data.results}
						closePopover={destroyPopover}
						refetchHandler={refetch}
					/>
				) : (
					<EmptyList />
				)}
			</Popover>
		</div>
	);
};

export default Notification;
