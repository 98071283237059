import React, { useRef, useState } from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';


//** Material UI
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
// ** custom components
import SearchSuggest from 'components/common/search/searchsuggest/SerachSuggest';
import Notification from 'components/notification/Notification';
import ShoppingCart from 'components/common/shoppingcart/ShoppingCart';
import LinkButton from '@core/components/linkbutton/LinkButton';
import UserProfileMenu from '@core/components/userprofilemenu/UserProfileMenu';

import classes from './DesktopMenuLoggedIn.module.scss';

import Logo from '@core/assets/logo/pharmuni-green.svg';
import ExploreIcon from '@core/assets/icons/explore.svg';
import JobBoardIcon from 'assets/icons/briefcase-icon.svg';
import DashboardIcon from '@core/assets/icons/dashboard.svg';

import VIPIcon from '@core/components/vipIcon/VIPIcon';
import ExploreMenu from 'components/common/ExploreMenu/ExploreMenu';

const DesktopLoggedIn = ({ vipAccess }) => {
	const router = useRouter();

	const [openExplore, setOpenExplore] = useState(false);
	const anchorRefExplore = useRef(null);
	const handleToggleExplore = () => {
		setOpenExplore((prevOpen) => !prevOpen);
	};
	const handleCloseExplore = () => {
		setOpenExplore(false);
	};
	return (
		<nav className={`${classes['nav-menu']} d-none d-xl-flex align-items-center`}>
			<div className="container d-flex justify-content-between  align-items-center">
				<Link href="/" replace>
					<Logo data-testid="logo" />
				</Link>
				<SearchSuggest type="desktop" />
				<ul
					className={`${classes['nav-links-loggedin']} d-flex justify-content-between m-0  gap-4`}>
					{/* <Link
						href="/"
						className={`${router.pathname === '/' ? classes['active-link-home'] : ''}`}>
						<HomeLogo />
						Home
					</Link> */}
					{/* <Link
						href="/explore?featured_contents=true"
						className={`${router.pathname === '/explore' ? classes['active-link'] : ''}`}>
						<ExploreIcon />
						<span>Explore</span>
					</Link> */}
					<Button
						ref={anchorRefExplore}
						id="explore-button"
						aria-controls={openExplore ? 'explore-menu' : undefined}
						aria-expanded={openExplore ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleToggleExplore}
						className={`${router.pathname === '/explore' ? classes['active-link'] : ''}`}
					>
						<ExploreIcon />
						Explore
					</Button>
					<Popper
						className={classes["explore-menu"]}
						open={openExplore}
						anchorEl={anchorRefExplore.current}
						role={undefined}
						placement="bottom"
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: 'top',
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleCloseExplore}>
										<span>
											<ExploreMenu />
										</span>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>

					{/* <Link
						href="/myuni"
						className={`${router.pathname === '/myuni' ? classes['active-link'] : ''}`}>
						<MyUniIcon />
						<span>My Uni</span>
					</Link> */}
					<Link
						href="/job-board"
						className={`${router.pathname === '/job-board' ? classes['active-link'] : ''}`}>
						<JobBoardIcon />
						<span>Job board</span>
					</Link>
					<Link
						href="/user-dashboard"
						className={`${router.pathname === '/user-dashboard' ? classes['active-link'] : ''}`}>
						{/* {vipAccess ? <VIPIcon active={router.pathname === '/profile'} /> : <ProfileIcon />} */}
						<DashboardIcon />
						<span>My Dashboard</span>
					</Link>
				</ul>
				<div className="d-flex justify-content-between align-items-center">
					{/* <LinkButton
						kind="secondary-simple"
						href="/company"
						target="_blank"
						rel="noreferrer"
						className={`${classes['get-started']}`}
					>
						Pharmuni Business
					</LinkButton> */}
					<Notification />
					{/* <Link
						href="/cart"
						className={`${router.pathname === '/cart' ? classes['active-cart-link'] : ''}`}>
						<ShoppingCart />
					</Link> */}
					<UserProfileMenu />
				</div>
			</div>
		</nav>
	);
};

export default DesktopLoggedIn;
