import { lazy } from 'react';

// ** TITLE TYPE CONSTANTS
import { MAIN_PAGE_TITLE, SUB_PAGE_TITLE, HIDDEN_TITLE } from '@core/constants/Title';
// ** Layout TYPE CONSTANTS
import { DEFAULT_LAYOUT, BLANK_LAYOUT, BLANK_MOBILE_LAYOUT } from '@core/constants/Layout';

const ProfileRoutes = [
	{
		path: '/profile',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Profile', type: MAIN_PAGE_TITLE }
		}
	},
	{
		path: '/certificates',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Certificates', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/faq',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'FAQ', type: SUB_PAGE_TITLE }
		}
	},
	{
		path: '/redeem-gift-code',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Redeem gift code', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-account',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'My Account', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/notification-setting',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Notification Settings', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/contact-us',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Contact us', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/deleted-account',
		exact: true,
		layout: BLANK_LAYOUT,
		meta: {
			title: { text: 'Delete Account', type: MAIN_PAGE_TITLE }
		}
	},
	{
		path: '/order-history',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Order History', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-resume',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'My resume', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-resume/new',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'My resume', type: HIDDEN_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-resume/about-me',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'About me', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-resume/carrer-goals',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Career goals', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-resume/work-experience/[id]',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Work experience', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-resume/education/[id]',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Education', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/profile/my-resume/skills',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'skills', type: SUB_PAGE_TITLE },
			protected: true
		}
	}
];

export default ProfileRoutes;
