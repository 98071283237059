// ** TITLE TYPE CONSTANTS
import { SUB_PAGE_TITLE, HIDDEN_TITLE, BLANK_TITLE, BLANK_WITH_TITLE } from '@core/constants/Title';
// ** Layout TYPE CONSTANTS
import { DEFAULT_LAYOUT, BLANK_MOBILE_LAYOUT } from '@core/constants/Layout';

const CompanyRoutes = [
	{
		path: '/company',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company', type: SUB_PAGE_TITLE },
		}
	},
	{
		path: '/company/new',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Company', type: BLANK_TITLE }
		}
	},
	{
		path: '/company/register',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Company Register', type: BLANK_TITLE },
			authentication: true
		}
	},
	{
		path: '/company/register-verify',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Verify Email', type: BLANK_TITLE }
		}
	},
	{
		path: '/company/verification',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Verify Email', type: BLANK_TITLE }
		}
	},
	{
		path: '/company/dashboard/job-posts/[id]',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Post a job', type: BLANK_WITH_TITLE },
			protected: true,
			isB2B: true
		}
	},
	{
		path: '/company/dashboard/license/purchase',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Job post purchase', type: BLANK_WITH_TITLE },
			protected: true,
			isB2B: true
		}
	},
	{
		path: '/company/job-board',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Job Board', type: BLANK_WITH_TITLE },
			protected: true,
			isB2B: true
		}
	},
	{
		path: '/company/my-account',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'My Account', type: BLANK_WITH_TITLE },
			protected: true,
			isB2B: true
		}
	},
	//-------------------------------
	{
		path: '/company/dashboard',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company Dashboard', type: HIDDEN_TITLE },
			protected: true,
			isB2B: true,
			isCompanyDashboard: true,
		}
	},
	{
		path: '/company/dashboard/job-posts-list',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company Job Posts', type: HIDDEN_TITLE },
			protected: true,
			isB2B: true,
			isCompanyDashboard: true,
		}
	},
	{
		path: '/company/dashboard/account',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company Account', type: HIDDEN_TITLE },
			protected: true,
			isB2B: true,
			isCompanyDashboard: true,
		}
	},
	{
		path: '/company/dashboard/user-management',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company User Management', type: HIDDEN_TITLE },
			protected: true,
			isB2B: true,
			isCompanyDashboard: true,
		}
	},
	{
		path: '/company/dashboard/departments',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company Departments', type: HIDDEN_TITLE },
			protected: true,
			isB2B: true,
			isCompanyDashboard: true,
		}
	},
	{
		path: '/company/dashboard/payment',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company Payment', type: HIDDEN_TITLE },
			protected: true,
			isB2B: true,
			isCompanyDashboard: true,
		}
	},
	{
		path: '/company/dashboard/applicants-details/[id]',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Applicants Details', type: HIDDEN_TITLE },
			protected: true,
			isB2B: true,
			isCompanyDashboard: true,
		}
	},
];

export default CompanyRoutes;
