import Animation from './Animation';

import useWindowSize from 'hooks/use-windowSize';

import Box from '@mui/material/Box';
import MaterialModal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

import CloseIcon from 'assets/icons/close-icon.svg';

import { NORMAL } from '@core/constants/ModalTypes';

import classes from './Modal.module.scss';

const Modal = ({
	children,
	title,
	actions,
	closeIcon,
	closeHandler,
	type = NORMAL,
	show = false,
	header = true,
	isMessaging = false,
	isGoPermium = false,
}) => {
	const { desktopDevice } = useWindowSize();
	return (
		<MaterialModal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={show}
			onClose={closeHandler}
			closeAfterTransition
			sx={{
				display: 'flex',
				alignItems: `${(isMessaging && desktopDevice) ? 'end' : 'center'}`,
				justifyContent: `${(isMessaging && desktopDevice) ? 'end' : 'center'}`,
				marginRight: `${(isMessaging && desktopDevice) ? '20px' : '0'}`,
				marginBottom: `${(isMessaging && desktopDevice) ? '20px' : '0'}`,
			}}
		>
			<Animation timeout={300} in={show} type={type}>
				<Box className={`${classes[type]}`}>
					<div>
						{header && (
							<section className={classes['modal-header']}>
								<IconButton onClick={closeHandler} data-testid="modal-close-btn">
									{closeIcon || <CloseIcon />}
								</IconButton>
								{title && <h1>{title}</h1>}
								<div className={classes['modal-actions']}>{actions}</div>
							</section>
						)}
						<section className={`${classes['modal-content']} ${(isMessaging) ? 'p-0' : ''} ${(isGoPermium) ? 'p-0 m-0' : ''}`}>
							{children}
						</section>
					</div>
				</Box>
			</Animation>
		</MaterialModal>
	);
};

export default Modal;
