import { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import UseModal from 'hooks/use-modal';

import { useQuery } from 'react-query';
import { userProfileService } from 'services/auth';
import { isUserLoggedIn, getAccessToken } from 'utils/authentication';
import { MOBILE_FULLSCREEN, NORMAL } from '@core/constants/ModalTypes';

import Avatar from 'components/pages/profile/Avatar';
import FullNameForm from 'components/pages/profile/FullNameForm';
import Modal from '@core/components/Modal/Modal';
import Spinner from '@core/components/spinner/Spinner';

// import material ui
import List from '@mui/material/List';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

// import icons
import SmsIcon from 'assets/icons/sms-icon.svg';
import CheckIcon from 'assets/icons/check-icon.svg';
import DashboardIcon from '@core/assets/icons/dashboard-icon.svg';
import DashboardResumeIcon from '@core/assets/icons/dashboard-my-resume.svg';
import DashboardCoverLetterIcon from '@core/assets/icons/dashboard-cover-letter.svg';
import DashboardCoursesIcon from '@core/assets/icons/dashboard-courses.svg';
import DashboardCertificateIcon from '@core/assets/icons/dashboard-certificate.svg';
import DashboardOrderHistoryIcon from '@core/assets/icons/dashboard-order-history.svg';

import classes from './DashboardMenuDesktop.module.scss';
import GoPermiumModal from '../gopermiummodal/GoPermiumModal';


const DashboardMenuDesktop = ({ isMobile = false }) => {

    const { data, isLoading, refetch } = useQuery(
        ['user-profile', isUserLoggedIn(), getAccessToken()],
        userProfileService,
        {
            cacheTime: 20 * 60 * 1000,
            staleTime: 20 * 60 * 1000
        }
    );
    const { showModal, destroyModal, show } = UseModal();
    const { showModal: showModalGoPermium, destroyModal: destroyModalGoPermium, show: showGoPermium } = UseModal();
    const router = useRouter();


    return (
        <>
            {data && !isLoading ? (
                <div className='position-relative'>
                    <div className={isMobile ? `${classes['wrapper']} ${classes['mobile']}` : `${classes['wrapper']}`}>
                        <div className={`${classes['user-box']}`}>
                            <div className={classes['user-image-wrapper']}>
                                <Avatar imageURL={data.data.image} refetchHandler={refetch} size='small' />
                            </div>
                            <div className={`${classes['info']}`}>
                                {data.data.is_name_verified ? (
                                    <div
                                        className={`${classes['fill-name']}`}>{`${data.data.first_name} ${data.data.last_name}`}</div>
                                ) : (
                                    <div
                                        className={`${classes['empty-name']}`}
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => showModal()}>
                                        Add your name
                                    </div>
                                )}
                                <div className={`${classes['user-email']}`}>
                                    {data.data.email}
                                    {data.data.is_verified && <CheckIcon />}
                                </div>
                            </div>
                        </div>
                        {!data.data.is_verified && (
                            <div className={`${classes['email-not-verified']}`}>
                                <div className={`${classes['msg-container']}`}>
                                    <SmsIcon />
                                    <div className={`${classes['text']}`}>Email not verified yet</div>
                                </div>
                                <div className={`${classes['action-container']}`}>
                                    <Link href="/verification">Verify now</Link>
                                    <div className={`${classes['icon']}`}></div>
                                </div>
                            </div>
                        )}
                        <Modal show={show} closeHandler={destroyModal} type={MOBILE_FULLSCREEN}>
                            <FullNameForm closeModal={destroyModal} refetchHandler={refetch} />
                        </Modal>
                        <MenuList
                            className={classes['dashboard-menu']}
                            component="div"
                            size="sm"
                            sx={{
                                boxShadow: 'sm',
                                flexGrow: 0,
                                overflow: 'auto',
                            }}
                        >
                            <List>
                                <MenuItem
                                    className={(router.pathname === '/user-dashboard' ? (`${classes["selected"]} ${classes['menu-item']}`) : classes['menu-item'])}
                                    onClick={() => router.push('/user-dashboard')}
                                >
                                    <DashboardIcon />
                                    Dashboard
                                </MenuItem>
                                <MenuItem
                                    className={`${router.pathname === '/my-applications' ? (`${classes["selected"]}`) : ''} ${(data?.data?.has_active_subscription ? '' : classes['go-permium'])} ${classes['menu-item']}`}
                                    onClick={() => { data?.data?.has_active_subscription ? router.push('/my-applications') : showModalGoPermium() }}
                                >
                                    <div>
                                        <DashboardCertificateIcon />
                                        My Applications
                                    </div>
                                    {!data?.data?.has_active_subscription && <span className={classes['permium-badge']}>Premium</span>}

                                </MenuItem>
                                <MenuItem
                                    className={`${router.pathname === '/profile/my-resume' ? (`${classes["selected"]}`) : ''} ${(data?.data?.has_active_subscription ? '' : classes['go-permium'])} ${classes['menu-item']}`}
                                    onClick={() => { data?.data?.has_active_subscription ? router.push('/profile/my-resume') : showModalGoPermium() }}
                                >
                                    <div>
                                        <DashboardResumeIcon />
                                        My Resume
                                    </div>
                                    {!data?.data?.has_active_subscription && <span className={classes['permium-badge']}>Premium</span>}
                                </MenuItem>
                                <MenuItem
                                    className={`${router.pathname.split("/")[1] === 'cover-letter' ? (`${classes["selected"]}`) : ''} ${(data?.data?.has_active_subscription ? '' : classes['go-permium'])} ${classes['menu-item']}`}
                                    onClick={() => { data?.data?.has_active_subscription ? router.push('/cover-letter') : showModalGoPermium() }}
                                >
                                    <div>
                                        <DashboardCoverLetterIcon />
                                        My Cover Letters
                                    </div>
                                    {!data?.data?.has_active_subscription && <span className={classes['permium-badge']}>Premium</span>}
                                </MenuItem>
                                <MenuItem
                                    className={(router.pathname === '/myuni' ? (`${classes["selected"]} ${classes['menu-item']}`) : classes['menu-item'])}
                                    onClick={() => router.push('/myuni')}
                                >
                                    <DashboardCoursesIcon />
                                    My Courses
                                </MenuItem>
                                <MenuItem
                                    className={(router.pathname === '/certificates' ? (`${classes["selected"]} ${classes['menu-item']}`) : classes['menu-item'])}
                                    onClick={() => router.push('/certificates')}
                                >
                                    <DashboardCertificateIcon />
                                    My Certificates
                                </MenuItem>
                                {/* <Link href={'/wishlist'}>
                                    <MenuItem
                                        className={(router.pathname === '/wishlist' ? (`${classes["selected"]} ${classes['menu-item']}`) : classes['menu-item'])}
                                    >
                                        <DashboardWishListIcon />
                                        My Wishlist
                                    </MenuItem>
                                </Link>*/}
                                {/* <MenuItem
                                    className={(router.pathname === '/order-history' ? (`${classes["selected"]} ${classes['menu-item']}`) : classes['menu-item'])}
                                    onClick={() => router.push('/order-history')}
                                >
                                    <DashboardOrderHistoryIcon />
                                    My Order History
                                </MenuItem> */}
                                {/*<Link href={'/redeem-gift-code'}>
                                    <MenuItem
                                        className={(router.pathname === '/redeem-gift-code' ? (`${classes["selected"]} ${classes['menu-item']}`) : classes['menu-item'])}
                                    >
                                        <DashboardRedeemIcon />
                                        Redeem Gift
                                    </MenuItem>
                                </Link> */}
                            </List>

                        </MenuList>
                        <Modal
                            show={showGoPermium}
                            closeHandler={destroyModalGoPermium}
                            type={NORMAL}
                            header={false}
                            isGoPermium={true}
                        >
                            <GoPermiumModal closeHandler={destroyModalGoPermium} />
                        </Modal>
                    </div>
                </div >
            ) : (
                <div className={classes['loading']}>
                    <Spinner />
                </div>
            )}
        </>
    );
};

export default DashboardMenuDesktop;