import React, { useState } from 'react';

import Link from 'next/link';

import UseModal from 'hooks/use-modal';

//** Material UI
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// ** custom components
import LogoutModal from 'components/pages/settings/LogoutModal';
import Modal from '@core/components/Modal/Modal';

import classes from './UserProfileMenu.module.scss';

import ProfileIcon from '@core/assets/icons/profile.svg';
import ProfileSimpleIcon from '@core/assets/icons/profile-simple.svg';
import SettingIcon from '@core/assets/icons/setting-menu.svg';
import LogOutIcon from '@core/assets/icons/logout-menu.svg';

const UserProfileMenu = ({ isBusiness = false }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseProfile = () => {
        setAnchorEl(null);
    };
    const {
        showModal: showLogoutModal,
        destroyModal: destroyLogoutModal,
        show: showLogout
    } = UseModal();
    return (
        <>
            <button
                className={classes["user-menu-button"]}
                aria-controls={!!anchorEl ? 'basic-menu' : undefined}
                aria-expanded={!!anchorEl ? 'true' : undefined}
                onClick={handleProfileClick}>
                <ProfileIcon />
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!anchorEl}
                onClose={handleCloseProfile}
                className={classes['user-menu']}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}>
                {isBusiness && (
                    <MenuItem >
                        <Link
                            className={classes["simple-link"]}
                            href="/company/my-account">
                            <ProfileSimpleIcon />
                            Account
                        </Link>
                    </MenuItem>
                )}

                {!isBusiness && (
                    <>
                        <MenuItem >
                            <Link
                                className={classes["simple-link"]}
                                href="/profile/my-account">
                                <ProfileSimpleIcon />
                                Account
                            </Link>
                        </MenuItem>
                        <MenuItem >
                            <Link
                                className={classes["simple-link"]}
                                href="/profile/notification-setting">
                                <SettingIcon />
                                Setting
                            </Link>
                        </MenuItem>
                    </>
                )}
                <MenuItem
                    className={classes["logout-link"]}
                    onClick={showLogoutModal}>
                    <LogOutIcon />
                    LogOut
                </MenuItem>
            </Menu>
            <Modal header={false} show={showLogout} closeHandler={destroyLogoutModal}>
                <LogoutModal closeModal={destroyLogoutModal} />
            </Modal>
        </>
    );
};

export default UserProfileMenu;