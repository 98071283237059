import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoggedIn: false,
	userProfile: null
};

const authSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logIn(state) {
			state.isLoggedIn = true;
		},
		logOut(state) {
			state.isLoggedIn = false;
			// remove uase data from store
			// remove token and refreshToken from localstorage
		}
	}
});

export const { logIn, logOut } = authSlice.actions;

export default authSlice.reducer;
